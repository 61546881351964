div#ClaimsBox {
  max-width: 960px;
  background-color: #526699;
  margin: 0 auto;
  border-radius: 8px;
  margin-top: -113px;
  z-index: 1111;
  position: relative;
  padding: 40px 90px;
  margin-bottom: 32px;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 0px;
}
.page-item.active .page-link {
  background-color: #1e306e;
  border-color: #1e306e;
  color: #fff !important;
  z-index: 3;
}
a.page-link {
  background-color: #526699;
  border: 1px solid #526699;
  color: #fff;
}

.page-item.disabled .page-link {
  color: #fff;
  pointer-events: none;
  background-color: #526699;
  border-color: #526699;
  opacity: 0.6;
}
.page-item:first-child .page-link {
  border-bottom-left-radius: 1000px;
  border-top-left-radius: 1000px;
}
.page-item:last-child .page-link {
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}
.page-link:hover {
  z-index: 2;
  color: #fff;
  background-color: #526699;
  border-color: #526699;
}
.page-link:focus {
  box-shadow: none;
}
@media (min-width: 320px) and (max-width: 767px) {
  ul.pagination {
    white-space: nowrap;
    padding-bottom: 10px;
  }
}
