footer {
  background-color: #526699;
  bottom: 0;
  float: left;
  left: 0;
  padding: 13px 0;
  position: absolute;
  right: 0;
  width: 100%;
}
footer,
footer a {
  color: #fff !important;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}
.text-center {
  text-align: center !important;
}
