input.round-red-btn.w-100.mt-3.clr {
  align-items: center;
  background: #1e306e;
  border: 1px solid #1e306e;
  border: 0;
  border-radius: 30px !important;
  color: #fff;
  display: flex;
  font-family: Sarabun, sans-serif;
  font-size: 18px;
  font-weight: 400;
  justify-content: center;
  padding: 10px 28px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
ul.timeline.claim_detail > li:last-child .form-box {
  margin-bottom: 0;
}
input.round-red-btn.w-100.mt-3.clr:hover {
  background: #526699 !important;
  border: 1px solid #526699 !important;
  color: #fff;
}

div#FileClaimsBox {
  max-width: 960px !important;
  margin: 0 auto !important;
  /* border-radius: 8px; */
  /* margin-top: -240px !important; */
  margin-top: -206px !important;
  z-index: 1111 !important;
  position: relative !important;
  /* padding: 1px 42px !important; */
}
input.round-red-btn.w-100.mt-3 {
  align-items: center;
  background: #526699;
  border: 1px solid #526699;
  border: 0;
  border-radius: 30px !important;
  color: #fff;
  display: flex;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  padding: 10px 28px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
input.round-red-btn.w-100.mt-3:hover {
  background: #1e306e !important;
  border: 1px solid #1e306e !important;
  color: #fff;
}
input#file {
  margin-top: 8px;
}
input[type="file"]::file-selector-button {
  border: 2px solid #abacac;
  padding: 0.2em 0.4em;
  /* border-radius: .2em; */
  background-color: #abacac;
  transition: 1s;
  margin-top: 1px;
}
label.radio-custom1-label:before {
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 23px;
  margin-right: 10px;
  margin-top: -2px;
  text-align: center;
  vertical-align: middle;
  width: 23px;
}
label.radio-custom1-label.is-danger {
  color: red;
}
.form-input {
  display: block;
  width: 100%;
  /* padding: 0.375rem 2.25rem 1.375rem 0.75rem; */
  padding: 14.375px 2.25rem 2.375rem 13.75px;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  padding-bottom: 17px;
  padding-left: 244px;
}
.form-floating.is-danger input.form-control,
.form-floating.is-danger .form-input {
  border: 1px solid red;
  color: red;
}
.claimPrice {
  background-color: #1e306e;
  color: #fff;
  text-align: center;
  padding-left: -17px;
  width: fit-content;
  /* margin-left: 156px; */
  position: relative;
  font-weight: 700;
  border-radius: 0.25rem;
  display: block;
  padding: 2px 10px;
  margin-left: auto;
}
.claimPrice2 {
  background-color: #1e306e;
  color: #fff;
  text-align: center;
  padding-left: -17px;
  width: fit-content;
  /* margin-left: 156px; */
  position: relative;
  font-weight: 700;
  border-radius: 0.25rem;
  display: block;
  padding: 2px 10px;
  margin-left: auto;
}
.claimPrice3 {
  background-color: #1e306e;
  color: #fff;
  text-align: center;
  padding-left: -17px;
  width: fit-content;
  /* margin-left: 156px; */
  position: relative;
  font-weight: 700;
  border-radius: 0.25rem;
  display: block;
  padding: 2px 10px;
  margin-left: auto;
}

span.spinner-border.spinner-border-sm2 {
  position: absolute !important;
  right: 12% !important;
  z-index: 1 !important;
  top: 49% !important;
}

span.spinner-border.spinner-border-sm3 {
  position: absolute !important;
  right: 12% !important;
  z-index: 1 !important;
  top: 81% !important;
}
span.spinner-border.spinner-border-smSplit1 {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
span.spinner-border.spinner-border-smSplit2 {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
  top: 81% !important;
}

.spinner-border-sm2 {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.spinner-border-sm3 {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

#image-container ul.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
#image-container ul.cards .card {
  position: relative;
  display: block;
  height: 100%;
  border-radius: calc(40 * 1px);
  overflow: hidden;
  text-decoration: none;
  padding-bottom: 91px;
  border: 0;
}

#image-container ul.cards .card__image {
  width: 100%;
  height: auto;
}
#image-container ul.cards .card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(40 * 1px);
  background-color: white;
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
}
#image-container ul.cards .card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 19px 20px;
  border-radius: 0;
  background-color: #1e306e;
  transform: translateY(-100%);
  transition: 0.2s ease-in-out;
}
#image-container ul.cards .card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}
#image-container ul.cards .card__status {
  font-size: 0.8em;
  color: white;
}
#image-container ul.cards .card__title {
  font-size: 22px;
  margin: 0 0 0.3em;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 269px;
}
#image-container ul.cards .card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}
#image-container ul.cards .card__arc path {
  fill: #1e306e;
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}
