.whitebg-box {
  max-width: 960px !important;
  background-color: #fff !important;
  box-shadow: 0 0 14px 0 #929292 !important;
  margin: 0 auto !important;
  /* border-radius: 8px; */
  /* margin-top: -262px !important; */
  margin-top: -190px !important;
  z-index: 1111 !important;
  position: relative !important;
  padding: 40px 35px !important;
}

li.fa.fa-angle-double-right {
  color: #1e306e;
}

li.circle1:before {
  border: 2px solid #1e306e;
  /* width: 30px !important; */
  border-radius: 50%;
  content: "";
  height: 12px;
  position: absolute;
  /* top: 6px; */
  width: 12px;
  margin-left: -17px;
  margin-top: 10px;
}

h2#register {
  color: #1e306e;
  font-family: Oswald, sans-serif;
  font-size: 40px;
  font-weight: 300;
  left: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: -126px;
  width: 100%;
  text-overflow: ellipsis;
  text-transform: uppercase;
  text-align: center;
}
section.slide-up.register {
  margin-bottom: 44px;
  margin-top: -190px;
}
@media (min-width: 320px) and (max-width: 767px) {
  section.slide-up.register {
    margin-top: -286px !important;
    margin-bottom: 53px !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  h2#register {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 22px !important;
    top: -65px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}
